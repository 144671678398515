export const useHttp = () => {
    const request = async (endPoint, body = null, method = 'POST',  headers = {'Content-Type': 'application/json'}) => {
        try {
            body = JSON.stringify(body);
            const response = await fetch(`https://mobile.insit.ru:43887/v2${endPoint}`, {method, body, headers, credentials: 'include'});

            if (!response.ok) {
                throw new Error(`Could not fetch ${'https://mobile.insit.ru:43887/v2' + endPoint}, status: ${response.status}`);
            }

            return await response.json();
        } catch(e) {
            throw e;
        }
    };

    return { request }
}