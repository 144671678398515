import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import arrowLeftSVG from "../../assets/vectors/arrowLeft.svg";
import "./payment.css"
import {fetchCheckPayment, fetchDoPayment} from "./PaymentSlice";

export default function Payment () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useLocation();
    const elem = data.state ? data.state.elem : [];
    const [sum, setSum] = useState('');
    const paymentSlice = useSelector(state => state.paymentSlice);

    const goTo = (href) => {
        navigate(href)
    }

    useEffect(() => {
        if(!data.state) {
            navigate('/profile')
        }
        // eslint-disable-next-line
    }, [])

    const onPay = () => {
        const requestData = {
            "customerId": data.state.customerId,
            "email": "",
            "phone": "",
            "serviceId": data.state.serviceId,
            "summa": +sum,
            "q": 0,
            "s": 3,
            "t": data.state.t
        }

        dispatch(fetchDoPayment(requestData))
    }

    useEffect(() => {
        setSum(elem.recommendedPay);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let data = {
            "orderId": paymentSlice.orderId,
        }
        if(paymentSlice.orderId) {
            dispatch(fetchCheckPayment(data));
        }
        // eslint-disable-next-line
    }, [paymentSlice.orderId]);

    useEffect(() => {
        if(paymentSlice.formUrl) {
            window.location.replace(paymentSlice.formUrl)
        }
        // eslint-disable-next-line
    }, [paymentSlice.formUrl]);

    return (
        <div className="payment">
            <img className="arrowLeft" alt='Вернуться' onClick={() => goTo(`/profile?sip=${data.state.t}`)} src={arrowLeftSVG}/>
            <div className="payment__info_block">
                <h4>{elem.tariffs[0] ? elem.tariffs[0].name.replaceAll('_', ' ') : ''}</h4>
                <h3>Баланс</h3>
                <h2>{ elem.balance ? (typeof elem.balance) === 'number' ? elem.balance : +(elem.balance.replace(',', '.')) : ''}&nbsp;₽</h2>
                <div className="recommended">
                    <p>Рекомендуется пополнить на:&nbsp;</p>
                    <h5>{elem.recommendedPay}&nbsp;₽</h5>
                </div>

            </div>
            <div className="payment__block">
                <h4>Сумма:</h4>
                <input type='text'
                       className='input_form'
                       id="paymentField"
                       value={sum}
                       onKeyDown={(a) => {
                           if(a.keyCode === 13) {
                               onPay()
                           }
                       }}
                       onInput={(elem) => {
                           elem.target.value = elem.target.value.replace(/[^0-9.]/g, '');
                           elem.target.value = elem.target.value.replace(/(\\..*)\\./g, '$1');
                           setSum(elem.target.value);
                       }}>
                </input>
                <button onClick={onPay}> Оплата картой</button>
            </div>
        </div>
    )
}