import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHttp } from "../../utils/services/useHttp";
import { useHttpTest } from "../../utils/services/useHttpTest";

const initialState = {
    loadingDoPayment: 'idle',
    loadingCheckPayment: 'idle',
    orderId: null,
    formUrl: null
};

export const fetchDoPayment = createAsyncThunk(
    'payment/fetchDoPayment',
    (data) => {
        const { request } = useHttpTest();
        return  request('/DoPayment', data)
    }
)

export const fetchCheckPayment = createAsyncThunk(
    'payment/fetchCheckPayment',
    (data) => {
        const { request } = useHttpTest();
        return  request('/CheckPayment', data)
    }
)

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDoPayment.pending, state => {state.loadingDoPayment = 'loading'})
            .addCase(fetchDoPayment.fulfilled, (state, action) => {
                state.orderId = action.payload.orderId;
                state.loadingDoPayment = 'idle';
                return state;
            })
            .addCase(fetchDoPayment.rejected, state => {state.loadingDoPayment = 'error'})

            .addCase(fetchCheckPayment.pending, state => {state.loadingCheckPayment = 'loading'})
            .addCase(fetchCheckPayment.fulfilled, (state, action) => {
                state.formUrl = action.payload.formUrl;
                state.loadingCheckPayment = 'idle';

                return state;
            })
            .addCase(fetchCheckPayment.rejected, state => {state.loadingCheckPayment = 'error'})
    }
});

const {actions, reducer} = paymentSlice;

export default reducer;