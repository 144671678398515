import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useHttp } from "../../utils/services/useHttp";

const initialState = {
    token: null,
    availableServices: [],
    connectedServices: [],
    customers: [],
    loadingMainCustomerServiceInfo: 'idle',
    loadingGetCurrentCustomersWithAddress: 'idle',
    loadingStatusService: 'idle',
    // loadingStatus: 'idle',
    // loadingStatusName: 'idle',
    loadingStatusConnections: 'idle',
    customerInfo: {},
    availableInternetConnections: false,
    promised: {
        loadingStatus: 'idle',
        accrued: false,
        promiseAmount: 0,
        activated: 0,
        promiseAllow: false
    },
    getPromised: {
        loadingStatus: 'idle',
    }
};

export const fetchGetTokens = createAsyncThunk(
    'serviceInfo/fetchGetTokens',
    (data) => {
        const { request } = useHttp();
        return  request('/GetTokens', data)
    }
)

export const fetchMainCustomerServiceInfo = createAsyncThunk(
    'serviceInfo/fetchMainCustomerServiceInfo',
    (data) => {
        const { request } = useHttp();
        return  request('/GetCustomerServiceInfo', data)
    }
)

export const GetCurrentCustomersWithAddress = createAsyncThunk(
    'serviceInfo/GetCurrentCustomersWithAddress',
    (data) => {
        const { request } = useHttp();
        return  request('/GetCurrentCustomersWithAddress')
    }
)

// export const fetchMainGetCustomerInfo = createAsyncThunk(
//     'serviceInfo/fetchMainGetCustomerInfo',
//     (data) => {
//         const { request } = useHttp();
//         return  request('/GetCustomerInfo', data)
//     }
// )

export const availableInternetConnections = createAsyncThunk(
    'serviceInfo/availableInternetConnections',
    (data) => {
        const { request } = useHttp();
        return  request('/AvailableInternetConnections', data)
    }
)

export const CheckPromised = createAsyncThunk(
    'serviceInfo/CheckPromised',
    (data) => {
        const { request } = useHttp();
        return  request('/CheckPromised', data)
    }
);

export const GetPromised = createAsyncThunk(
    'serviceInfo/GetPromised',
    (data) => {
        const { request } = useHttp();
        return  request('/GetPromised', data)
    }
);

const personalAccountSlice = createSlice({
    name: 'serviceInfo',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMainCustomerServiceInfo.pending, state => {state.loadingMainCustomerServiceInfo = 'loading'})
            .addCase(fetchMainCustomerServiceInfo.fulfilled, (state, action) => {
                const newServiceData = []

                action.payload.connectedServices.map((service) => {
                    if( service.type === "INTERNET" ) {
                        let dataVideo = {},
                            key = false;
                        service.tariffs.map((tariff) => {
                            if(tariff.name.includes("Облачное")){
                                dataVideo = {
                                    type: "VIDEO",
                                    subscriptionBegin: "",
                                    subscriptionExpire:	"",
                                    isSubscription:	false,
                                    recommendedPay:	0,
                                    balance: 0,
                                    tariffs: [tariff]
                                }
                                key = true;
                            }
                        })
                        if(key) {
                            newServiceData.push(dataVideo)
                        }
                        let filterData = service.tariffs.filter((tariff) => {
                            return !tariff.name.includes("Облачное")
                        })

                        if (filterData.length > 0) {
                            newServiceData.push({
                                type: service.type,
                                subscriptionBegin: service.subscriptionBegin,
                                subscriptionExpire:	service.subscriptionExpire,
                                isSubscription:	service.isSubscription,
                                recommendedPay:	service.recommendedPay,
                                balance: service.balance,
                                tariffs: filterData
                            })
                        }
                    } else {
                        newServiceData.push(service)
                    }
                })

                state.availableServices = action.payload.availableServices;
                state.connectedServices = newServiceData.reverse();
                state.loadingMainCustomerServiceInfo = 'idle';

                return state;
            })
            .addCase(fetchMainCustomerServiceInfo.rejected, state => {state.loadingMainCustomerServiceInfo = 'error'})

            .addCase(GetCurrentCustomersWithAddress.pending, state => {state.loadingGetCurrentCustomersWithAddress = 'loading'})
            .addCase(GetCurrentCustomersWithAddress.fulfilled, (state, action) => {
                state.customers = action.payload.customers;
                state.loadingGetCurrentCustomersWithAddress = 'idle';
                action.payload.customers.map((elem) => {
                    state.customerInfo[elem.customerId] = elem.name;
                })
                return state;
            })
            .addCase(GetCurrentCustomersWithAddress.rejected, state => {state.loadingGetCurrentCustomersWithAddress = 'error'})

            // .addCase(fetchMainGetCustomerInfo.pending, state => {state.loadingStatusName = 'loading'})
            // .addCase(fetchMainGetCustomerInfo.fulfilled, (state, action) => {
            //     action.payload.fullName = action.payload.fullName.split(' ')[1]
            //     state.customerInfo = action.payload;
            //     state.loadingStatusName = 'idle';
            //
            //     return state;
            // })
            // .addCase(fetchMainGetCustomerInfo.rejected, state => {state.loadingStatusName = 'error'})

            .addCase(availableInternetConnections.pending, state => {state.loadingStatusConnections = 'loading'})
            .addCase(availableInternetConnections.fulfilled, (state, action) => {
                state.availableInternetConnections = action.payload;
                state.loadingStatusConnections = 'idle';

                return state;
            })
            .addCase(availableInternetConnections.rejected, state => {state.loadingStatusConnections = 'error'})


            .addCase(fetchGetTokens.pending, state => {state.loadingStatusConnections = 'loading'})
            .addCase(fetchGetTokens.fulfilled, (state, action) => {
                // state.availableInternetConnections = action.payload;
                state.token = action.payload.Status;
                // state.loadingStatusConnections = 'idle';
                return state;
            })
            .addCase(fetchGetTokens.rejected, state => {state.loadingStatusConnections = 'error'})

            .addCase(CheckPromised.pending, state => {state.promised.loadingStatus = 'loading'})
            .addCase(CheckPromised.fulfilled, (state, action) => {
                state.promised = action.payload;
                state.promised.loadingStatus = 'idle';

                return state;
            })
            .addCase(CheckPromised.rejected, state => {state.promised.loadingStatus = 'error'})

            .addCase(GetPromised.pending, state => {state.getPromised.loadingStatus = 'loading'})
            .addCase(GetPromised.fulfilled, (state, action) => {
                state.getPromised = action.payload;
                state.getPromised.loadingStatus = 'idle';

                return state;
            })
            .addCase(GetPromised.rejected, state => {state.getPromised.loadingStatus = 'error'})

    //         .addCase(deleteCustomerServiceInfo.fulfilled, (state, action) => {
    //             state = action.payload;
    //             return state;
    //         })
            .addDefaultCase(() => {})
    }
});

const {actions, reducer} = personalAccountSlice;

export default reducer;